import React from 'react';
import Chart from 'react-apexcharts';
import {
  GRAPH_TREND,
  LABEL_CONSTANT,
  LABEL_KG,
  ONE_MONTH,
  SEVEN_DAYS,
  SEVEN_MONTHS,
  WEIGHT_GAIN_OVER_TIME,
  WEIGHT_GRAPH_HEADING,
  WEIGHT_GRAPH_TYPE,
  WEIGHT_LOSS_OVER_TIME,
  WEIGHT_MAINTAIN_OVER_TIME
} from '../../../../common/commonConstant';
import variables from '../../../../common/commonConstant.scss';
import './TargetWeightGraph.scss';
import { round } from '../../../../services';
import { thaiDate } from '../../../../utill.func';

export default function TargetWeightGraph({ series, goalType = GRAPH_TREND.SAME, graphType, minValue = 0, onClick = () => { } }) {

  const firstWeight = (series[0]?.data?.length && series[0].data[0]?.y) ?? 0;
  const lastWeight = (series[0]?.data?.length && series[0].data[(series[0].data?.length ?? 0) - 1]?.y) ?? 0;
  const trend = firstWeight < lastWeight ? GRAPH_TREND.INCREASE : (firstWeight > lastWeight ? GRAPH_TREND.DECREASE : GRAPH_TREND.SAME);

  const GRAPH_TYPE_MAPPING = {
    [GRAPH_TREND.INCREASE]: WEIGHT_GAIN_OVER_TIME,
    [GRAPH_TREND.DECREASE]: WEIGHT_LOSS_OVER_TIME,
    [GRAPH_TREND.SAME]: WEIGHT_MAINTAIN_OVER_TIME,
  };

  const GRAPH_DURATION_MAPPING = {
    [WEIGHT_GRAPH_TYPE.ANNUALLY]: SEVEN_MONTHS,
    [WEIGHT_GRAPH_TYPE.MONTHLY]: ONE_MONTH,
    [WEIGHT_GRAPH_TYPE.WEEKLY]: SEVEN_DAYS,
  }

  const options = {
    chart: {
      id: 'amway-line-graph',
      type: 'area',
      foreColor: variables.homegrey3,
      toolbar: {
        autoSelected: 'pan',
        show: false,
      },
      events: {
        click: onClick
      }
    },
    legend: { show: false },
    colors: [((trend === goalType) || (trend === GRAPH_TREND.SAME)) ? variables.homeGreenLight1 : variables.homeMaroonLight1],
    fill: {
      gradient: {
        colors: [((trend === goalType) || (trend === GRAPH_TREND.SAME)) ? variables.homeGreenLight1 : variables.homeMaroonLight1],
        enabled: true,
        gradientToColors: [((trend === goalType) || (trend === GRAPH_TREND.SAME)) ? variables.homeGreenLight2 : variables.homeMaroonLight2],
      },
    },
    stroke: {
      colors: [((trend === goalType) || (trend === GRAPH_TREND.SAME)) ? variables.homeGreenDark : variables.homeMaroonDark],
      curve: 'straight',
      width: 4,
    },
    grid: {
      yaxis: {
        lines: {
          show: false, // Hide the horizontal grid lines on the Y-axis
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    xaxis: {
      crosshairs: {
        show: false,
      },
      axisBorder: {
        show: false, // Hide the x-axis line
      },
      tooltip: {
        enabled: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: variables.homegrey5,
          fontFamily: variables.sukhmaiText,
        },
        offsetY: 6,
        rotate: 0,
        hideOverlappingLabels: false,
        ...graphType === WEIGHT_GRAPH_TYPE.MONTHLY ? { formatter: (label) => label && thaiDate(label, 'MMM') } : {},
      },
    },
    yaxis: {
      tickPlacement: 'between',
      min: minValue,
      tooltip: {
        enabled: false,
      },
      labels: {
        style: {
          colors: variables.homegrey5,
          fontFamily: variables.gtWalsheimpro,
        },
        offsetX: -12,
      },
      tickAmount: 3,
    },
  };

  return <div className="target-weight-graph">
    <div className="target-weight-graph-heading">{WEIGHT_GRAPH_HEADING}</div>
    <div className={`target-weight-graph-wrapper --${graphType}`}>
      <Chart options={options} series={series} type="area" />
      <div className="target-weight-graph-value">{lastWeight}<span className="target-weight-graph-unit">{LABEL_KG}</span></div>
    </div>
    <div className="target-weight-graph_footer">
      <div className={`target-weight-graph_title ${trend !== goalType ? `--${GRAPH_TREND.DIFFERENT}` : `--${GRAPH_TREND.SAME}`}`}>
        <div className={`target-weight-graph_title-arrow ${trend === GRAPH_TREND.INCREASE ? '--upward' : (trend === GRAPH_TREND.DECREASE ? '--downward' : `--${GRAPH_TREND.SAME}`)}`} />
        <div className={`target-weight-graph_title-value ${trend !== goalType ? '' : '--thai'}`}>{trend !== goalType ? Math.abs(round(firstWeight - lastWeight)) : LABEL_CONSTANT}</div>
        {trend !== goalType && <div className={'target-weight-graph_title-unit'}>{LABEL_KG}</div>}
      </div>
      <div className="target-weight-graph_footer_text">{GRAPH_TYPE_MAPPING[goalType]} <span>{GRAPH_DURATION_MAPPING[graphType].number}</span>{GRAPH_DURATION_MAPPING[graphType].text}</div>
    </div>
    <div onClick={onClick} className="target-weight-graph-overlay" />
  </div>;
}
